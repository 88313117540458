
const initialState = {};
 
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN':
      return { 
        ...state,
        isLoggedIn: true,
        mall_name: action.payload.data.mall_name,
        mall_image: action.payload.data.mall_image,
        mall_id: action.payload.data.mall_id,
        table_id: action.payload.data.table_id,
        table_name: action.payload.data.table_name,
        isLoading: false,
      };
    case 'LOGOUT':
      return { ...state,
        isLoggedIn: false,
        mall: '',
        mamallll: '',
        isLoading: false,
      };
    case 'LOADING':
      return { ...state, isLoading: action.payload };
    case 'ADD_PRODUCT':
      return { ...state,
        cart: [...state.cart, action.payload],
      };
    case 'REMOVE_PRODUCT':
      return { ...state,
        cart: state.cart.filter((product) => product.id !== action.payload),
      };
    default:
      return state;
  }
};

//const store = createStore(rootReducer);

export default rootReducer;
