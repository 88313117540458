import * as React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/FavoriteBorder';
import AddIcon from '@mui/icons-material/AddCircle';
import { currencyFormater, getImage } from '../../general/utils';

import { addProduct, removeProduct } from '../../general/actions';
import { HOST } from '../../general/utils';

import { useTranslation } from 'react-i18next';

export default function ProductCard({ product, openProductView }) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const handleAddProduct = (product) => {
    addProduct(product);
  };

  const handleRemoveProduct = (productId) => {
    removeProduct(productId);
  };

  return (
    <Card sx={{ display: 'flex' }} onClick={openProductView}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
            {i18n.language == 'en' ?
              product.name_en
              :
              product.name
            }
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {product.style ? <>
              {t('label_style')}{': '}
              {i18n.language == 'en' ?
                product.style_en
                :
                product.style
              }
            </>
              : ''}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {product.abv ? <>
              ABV: {product.abv}%
            </> : ' '}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {product.ibu ? <>
              IBU: {product.ibu}
            </> : ' '}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {t('label_price_from')}{': '}
            {currencyFormater(product.sizes && product.sizes[0].pivot.price)}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <IconButton aria-label="play/pause">
            <FavoriteIcon sx={{ height: 38, width: 38 }} />
          </IconButton>
          <IconButton aria-label="next" onClick={() => handleAddProduct(product)}>
            {theme.direction === 'rtl' ? <AddIcon /> : <AddIcon />}
          </IconButton>
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={HOST + product.image}
        alt={product.name}
      />
    </Card>
  );
}
