function login(user) {
  return {
    type: 'LOGIN',
    payload: user
  };
}

function logout() {
  return {
    type: 'LOGOUT'
  };
}

function setIsLoading(isLoading) {
  return {
    type: 'LOADING',
    payload: isLoading
  };
}

function addProduct(product) {
  return {
    type: 'ADD_PRODUCT',
    payload: product,
  };
}

function removeProduct(productId) {
  return {
    type: 'REMOVE_PRODUCT',
    payload: productId,
  };
}

export { login, logout,  setIsLoading,  addProduct, removeProduct };