import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from '../user/login';
import Logout from '../user/logout';
import Home from '../home';
import PrivateRoute from './privateRoute';
import Navigation from './main';
import Products from '../menu/list';

function AppRouter() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route exact path="" element={<Navigation />}>
          <Route exact path="/login/:tableToken" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
          {/* Private Routes */}
          <Route exact path="/products" element={<PrivateRoute component={Products} />} />
          <Route exact path="/logout" element={<PrivateRoute component={Logout} />} />

        </Route>
        <Route exact path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
