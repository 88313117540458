import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import StoreSliderCard from './storeSliderCard';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardMedia, CardActions, CardActionArea } from '@mui/material';
import { HOST } from '../general/utils';


import axiosInstance from '../general/axiosInstance';

function StoreSlider(props) {
    const { t } = useTranslation();
    const [storeFilter, setStoreFilter] = useState();
    const [stores, setStores] = useState([]);

    const settings = {
        dots: false,
        infinite: stores.length > 1 ? true : false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        rows: 1,
        nextArrow: <></>,
        prevArrow: <></>,
        centerMode: true,
        centerPadding: "10px",
    };

    const getStores = async () => {
        try {
            const response = await axiosInstance.get(`stores/by-mall`);
            setStores(response.data);

            if (!response.data.length) {
                alert('No existen stores');
            }
        } catch (error) {
            alert('No existen stores');
        } finally {
        }
    }

    useEffect(() => {
        props.setStoreFilter(storeFilter);
    }, [storeFilter]);

    // initialize
    useEffect(() => {
        getStores();
    }, []);


    return (
        <div>
            {stores.length > 1 ?
                <>
                    <h2>{t('label_taps')}</h2>
                    <Slider {...settings}>
                        {stores.map(store => (
                            <StoreSliderCard store={store} action={setStoreFilter} />
                        ))}
                    </Slider>
                </>
                :
                <>
                    <Card sx={{ width: '100%' }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="240"
                                image={HOST + (stores[0] ? stores[0].image : '')}
                                alt=""
                            />
                        </CardActionArea>
                        <CardActions>
                        </CardActions>
                    </Card>
                </>}
        </div>
    );
}

export default StoreSlider;