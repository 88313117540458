import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Card, CardMedia, CardActions, Typography, CardContent } from '@mui/material';
import Product from './products/productCard';
import { HOST } from '../general/utils';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/FormatListBulleted';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

function CategoriesSlider(props) {
    const { categories, products } = props;
    const { t, i18n } = useTranslation();

    const [category, setCategory] = useState(categories[0].id);

    const settings = {
        dots: false,
        infinite: categories.length > 1 ? true : false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        rows: 1,
        centerPadding: "60px",
        nextArrow: <></>,
        prevArrow: <></>,
        centerMode: true,
        centerPadding: "30px",
    };

    return (
        <div>
            <Slider {...settings}>
                {categories.map(product => (
                    <Box key={product.id}>
                        <Card sx={{ height: '10rem', position: 'relative', }}  onClick={() => setCategory(product.id)}>
                            <CardContent>
                                <Typography variant="h6" noWrap>
                                    {i18n.language == 'en' ?
                                        product.name_en
                                        :
                                        product.name
                                    }
                                </Typography>
                            </CardContent>
                            <CardMedia image={HOST + product.image} title={product.name} style={{ height: 0, paddingTop: '70%' }} />
                            <CardContent>

                            </CardContent>
                            <CardActions style={{
                                position: 'absolute',
                                bottom: -10,
                                left: '65%',
                                right: 0,
                            }}>
                                <IconButton >
                                    <AddIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Box>
                ))}
            </Slider>

            {category ? products.map(store => (
                <div key={store.id}>
                    {store.product.filter((product) => {
                        return product.category_id == category;
                    }).map(product => (
                        <div key={product.id}>
                            <Product product={product}></Product>
                        </div>
                    ))}
                </div>
            )) : ''}
        </div>
    );
}

export default CategoriesSlider;