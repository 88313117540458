import axios from "axios";
import { logout } from '../general/actions';
import { connect } from 'react-redux';
import i18n from 'i18next';

const API_HOST = process.env.REACT_APP_API_HOST;
axios.defaults.baseURL = API_HOST;

const axiosInstance = axios.create({
  headers: {
    //Authorization: `Bearer ${localStorage.token}`,
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {

    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers['Accept-Language'] = i18n.language;

    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 500 || error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(axiosInstance);
