import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function PrivateRoute({ component: Component, ...props }) {
  const { isLoggedIn } = props;
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  return isLoggedIn && <Component />;
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn
  };
}

export default connect(mapStateToProps)(PrivateRoute);
