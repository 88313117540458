import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../images/logo.gif';

import { useTranslation } from 'react-i18next';

function Home(props) {
    const { isLoggedIn, isLoading } = props;
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/products");
        }
    }, [isLoggedIn]);

    return (
        <>{!isLoggedIn &&
            <div align="center">
                <br />
                <br />
                <br />
                <h2 >
                    {isLoading ? 
                        t('home_loadingMsg')
                        :
                        t('home_scanMsg')
                    }
                </h2>
                <br />
                <img src={logo} height="350rem" className="d-inline-block align-top rounded" />
            </div>
        }</>
    );
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);