import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            welcome: 'Welcome to ',
            changeLanguage: 'Change language',
            home_scanMsg: 'Scan the QR code on your table to order',
            home_loadingMsg: 'Loading your table info...',
            label_categories: 'Categories',
            label_back: 'Go back',
            label_add: 'Add to the order',
            label_taps: 'Taps',
            label_style: 'Style',
            label_price_from: 'From',
            label_size: 'Size',
            label_search_all: 'Search in all taps',
            menu_button_see_orders: 'See orders',
            menu_button_make_order: 'Order now',
            menu_button_favorites: 'Favorites',
        },
    },
    es: {
        translation: {
            welcome: 'Bienvenido a ',
            changeLanguage: 'Cambiar idioma',
            home_scanMsg: 'Usa tu celular para scanear el QR en tu mesa y ordenar',
            home_loadingMsg: 'Cargando productos de tu mesa...',
            label_categories: 'Categorias',
            label_back: 'Atrás',
            label_add: 'Agregar al pedido',
            label_taps: 'Nuestros Bares',
            label_style: 'Estilo',
            label_price_from: 'Desde',
            label_size: 'Presentación',
            label_search_all: 'Buscar en todos los bares',
            menu_button_see_orders: 'Ver Pedidos',
            menu_button_make_order: 'Pedir ahora',
            menu_button_favorites: 'Favoritos',
        },
    },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es', 
    keySeparator: false, 
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
