import React, { Component } from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import { HOST } from '../general/utils';

function StoreSliderCard(props) {
    const selectStore = (id)=>{
        props.action(id);
    }
    console.log(props)
    return (
        <Card sx={{ width: '7rem' }} onClick={()=>selectStore(props.store.id)}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="100"
                    image={ HOST + props.store.image }
                    alt=""
                />
            </CardActionArea>
            <CardActions>
            </CardActions>
        </Card>
    );
}

export default StoreSliderCard;