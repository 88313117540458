import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/Send';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function BottomNavigationBar(props) {
  const { isLoggedIn, username } = props;
  const [value, setValue] = useState(0);

  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label={t('menu_button_see_orders')} icon={<RestoreIcon />} />
        <BottomNavigationAction label={t('menu_button_make_order')} icon={<LocationOnIcon />} />
        <BottomNavigationAction label={t('menu_button_favorites')} icon={<FavoriteIcon />} />
      </BottomNavigation>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

export default connect(mapStateToProps)(BottomNavigationBar);