import React, { Component, useState } from "react";
import Slider from "react-slick";
import FavoriteIcon from '@mui/icons-material/FavoriteBorder';
import AddIcon from '@mui/icons-material/AddCircle';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { currencyFormater, getImage } from '../general/utils';
import ProductView from './products/productDetail';
import { HOST } from '../general/utils';
import { Box, Card, CardMedia, CardActions, CardActionArea, Typography, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

function StoreProductsSlider(props) {
    const [productSelected, setProductSelected] = useState([]);
    const [show, setShow] = useState(false);
    const { stores } = props;
    const { t, i18n } = useTranslation();

    const settings = {
        dots: false,
        infinite: stores.length > 1 ? true : false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        rows: 1,
        centerPadding: "60px",
        nextArrow: <></>,
        prevArrow: <></>,
        centerMode: true,
        centerPadding: "20px",
    }
    return (
        <div>
            <Slider {...settings}>
                {stores.map(product => (
                    <Box key={product.id} >
                        <Card sx={{ height: '18rem', position: 'relative', }} onClick={() => { setShow(true); setProductSelected(product) }}>
                            <CardContent>
                                <Typography variant="h7" noWrap>
                                    {i18n.language == 'en' ?
                                        product.name_en
                                        :
                                        product.name
                                    }
                                </Typography>
                            </CardContent>
                            <CardMedia image={HOST + product.image} title={product.name} style={{ height: 0, paddingTop: '70%' }} />
                            <CardContent>
                                <Typography variant="subtitle1" color="textSecondary" >
                                    {i18n.language == 'en' ?
                                        product.style_en
                                        :
                                        product.style
                                    }
                                    {!product.style ? ' ' : ''}
                                </Typography>
                                <Typography  color="textSecondary" noWrap variant="subtitle2">
                                    {product.abv && product.ibu ? <>
                                    ABV: {product.abv}%  IBU: {product.ibu}
                                     </>
                                    :' '}
                                </Typography>
                                <Typography color="textSecondary" >
                                    {currencyFormater(product.sizes && product.sizes[0].pivot.price)}
                                </Typography>
                            </CardContent>
                            <CardActions  style={{
                                position: 'absolute',
                                bottom: 0,
                                left: '65%',
                                right: 0,
                            }}>
                                <IconButton  style={{display: 'none'}}>
                                    <FavoriteIcon />
                                </IconButton>
                                <IconButton >
                                    <AddIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Box>
                ))}
            </Slider>
            <ProductView
                show={show} setShow={setShow}
                product={productSelected}
            />
        </div >
    );
}

export default StoreProductsSlider;