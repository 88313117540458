import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import rootReducer from './modules/general/store';
import thunk from 'redux-thunk';
import { createStore, dispatch, applyMiddleware } from 'redux';
import axiosInstance from './modules/general/axiosInstance';
import { login, logout } from './modules/general/actions';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore(rootReducer, applyMiddleware(thunk));

const LoadInitialState = () => {
  return async (dispatch) => {

      const token = localStorage.getItem('token');
      await axiosInstance.get('/user-qr', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        dispatch(login({data: response.data}));
      })
      .catch(err => {
        dispatch(logout());
    });

  };
};

store.dispatch(LoadInitialState()).then(() => {
  root.render(
    <React.StrictMode>
      <App store={store}/>
    </React.StrictMode>
  );
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
