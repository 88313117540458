import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@mui/material';
import { HOST } from './utils';
import { connect } from 'react-redux';
import loading from '../../images/beeer-loading4.gif';

function WelcomeModal(props) {
    const {mall_image } = props;

    const [variant, setVariant] = useState(!props.variant ? 'light' : props.variant);
    const [show, setShow] = useState(props.show);
    const [msg, setMsg] = useState(props.msg  || '');
    const [title, setTtle] = useState(props.title || '');

    const onClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }

        // Close the modal
        setShow(false);
        props.setShow(false);

        (props.setOnClose) && props.setOnClose(null);
        (props.setOnConfirm) && props.setOnConfirm(null);

    }

    const onConfirm = () => {
        if (typeof props.onConfirm === 'function') {
            props.onConfirm();
            props.setOnConfirm(null);
        }
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        setMsg(props.msg);
    }, [props.msg]);

    useEffect(() => {
        setVariant(props.variant);
    }, [props.variant]);

    return (
        <>
            <div>
                <Dialog open={show} onClose={onClose} fullScreen>                 
                    <DialogTitle></DialogTitle>
                    <DialogContent align='center'  style={{backgroundColor: '#382D3E'}}>
                        <h2 mt={2}>{msg}</h2>
                        <img src={loading} height="200rem" className="d-inline-block align-top rounded" />
                        <br></br>
                        <img src={ HOST + mall_image} height="300rem" className="d-inline-block align-top rounded" />
                    </DialogContent>
                    <DialogActions>
                        
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        mall_name: state.mall_name,
        mall_image: state.mall_image,
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);