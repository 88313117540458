import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { currencyFormater } from '../../general/utils';
import { HOST } from '../../general/utils';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function ProductView(props) {
    const { mall_name } = props;
    const { t, i18n } = useTranslation();

    const [product, setProduct] = useState(props.product);
    const [open, setOpen] = useState(props.show);

    useEffect(() => {
        props.setShow(open);
    }, [open]);

    useEffect(() => {
        setOpen(props.show);
    }, [props.show]);

    useEffect(() => {
        setProduct(props.product);
    }, [props.product]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
            <DialogTitle>
                {mall_name} {' - '}
                {i18n.language == 'en' ?
                    product.name_en
                    :
                    product.name
                }
            </DialogTitle>
            <DialogContent>
                <Card sx={{ maxWidth: '100%' }}>
                    <CardMedia
                        sx={{ height: '20rem' }}
                        image={HOST + product.image}
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {i18n.language == 'en' ?
                                product.style_en
                                :
                                product.style
                            }
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {i18n.language == 'en' ?
                                product.description_en
                                :
                                product.description
                            }
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {product.abv && product.ibu ? <>
                            ABV: {product.abv}% IBU: {product.ibu}
                            </>: ' '}
                        </Typography>
                        <br></br>
                        <Typography variant="body2" color="text.secondary">
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">{t('label_size')}{': '}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="size-price"
                                    defaultValue={product.sizes && product.sizes[0]?.pivot.price}
                                >
                                    {product.sizes &&
                                        product.sizes.map(size => (
                                            <FormControlLabel
                                                key={size.pivot.price}
                                                value={size.pivot.price}
                                                control={<Radio />}
                                                label={
                                                    i18n.language === 'en' ?
                                                        size.name_en + ' ' + currencyFormater(size.pivot.price) + ' COP'
                                                        : size.name + ' ' + currencyFormater(size.pivot.price)
                                                }
                                            />
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Typography>
                    </CardContent>
                    <CardActions style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                            }}>
                        <Button>{t('label_add')}</Button>
                        <Button>{t('menu_button_make_order')}</Button>
                    </CardActions>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    {t('label_back')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


function mapStateToProps(state) {
    return {
        mall_name: state.mall_name,
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);

