
import { Provider } from 'react-redux';
import AppRouter from './modules/navigation/appRouter';
import StopUser from './modules/general/stopUser';
import { useEffect } from 'react';
import axiosInstance from './modules/general/axiosInstance';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './index.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

darkTheme.spacing(2); 

function App(props) {

  useEffect(() => {
/*
    localStorage.setItem('count_pending_approve', 0);

    const intervalId = setInterval(() => {
      if (localStorage.token !== undefined) {
        getCountPendingOrders();
      }
    }, 60000); // 1 minutes = 60000

    return () => {
      clearInterval(intervalId);
    };
*/
  }, []);

  const getCountPendingOrders = async () => {
    const response = await axiosInstance.get('/orders/pending-count');
    let countPendingOrders = parseInt(response.data);
    if (countPendingOrders > 0 && countPendingOrders > parseInt(localStorage.count_pending_approve)) {
      new Notification(
        'Notificación Abi:',
        {
          body: 'Tienes ' + countPendingOrders + ' pedido' + (countPendingOrders > 1 ? 's' : '') + ' por aprobar.',
          icon: "logo192.png"
        }

      );
      localStorage.setItem('count_pending_approve', countPendingOrders);
    }
  }



  return (
    <Provider store={props.store}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <AppRouter />
        <StopUser />
      </ThemeProvider>
    </Provider >
  );
}

export default App;