
const HOST = process.env.REACT_APP_HOST;
const DEFAULT_IMG = 'storage/images/system/product-default.png';

const getImage = (url)=>{
    if (url && url.includes('storage')) {
        return HOST + url
    } else if(url) {
        return url;
    } else {
        return HOST + DEFAULT_IMG;
    }
}

const currencyFormater  = (value)=>{
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value)
}


export { currencyFormater, getImage, HOST,  DEFAULT_IMG };