import { connect } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function StopUser(props) {
    const { isLoading } = props;

    if (!isLoading) {
        return '';
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );

}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading
    };
}

export default connect(mapStateToProps)(StopUser);