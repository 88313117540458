import { useEffect } from 'react';
import axiosInstance from '../general/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../general/actions';

const Logout = (props) => {
  const { isLoggedIn, logout } = props;
  const navigate = useNavigate();

  function closeSession() {
    axiosInstance.post('/logout')
      .then(response => {
        // Handle response
        logout();
        localStorage.clear();
        navigate('/home');
      })
      .catch(error => {
        // Handle error
        logout();
        localStorage.clear();
        navigate('/home');
        console.log(error);
      });
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/home");
    } else {
      closeSession();
    }
  }, [isLoggedIn, navigate, closeSession]);

  return;
};

function mapStateToProps(state) {
  return {
    username: state.username,
    isLoggedIn: state.isLoggedIn
  };
}

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
