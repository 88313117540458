import { setIsLoading } from '../general/actions';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WelcomeModal from '../general/welcomeModal';

import { Dialog, InputAdornment, DialogTitle, DialogContent, DialogActions, Grid, Card, CardMedia, CardContent, CardActions, Typography, TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import StoreSlider from "./storeSlider";
import StoreProductsSlider from "./storeProductsSlider";
import CategoriesSlider from "./categoriesSlider";

import ProductCard from "./products/productCard";
import ProductView from './products/productDetail';

import { useTranslation } from 'react-i18next';

function Products(props) {
    const { setIsLoading, mall, table, mall_name, mall_image } = props;

    const { t } = useTranslation();

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [storeFilter, setStoreFilter] = useState('');

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('info');

    const [ productSelected, setProductSelected ] = useState([]);
    const [ showProductView, setShowProductView ] = useState(false);

    const [showCategories, setShowCategories] = useState(false);

    const navigate = useNavigate();

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');

    // initialize
    useEffect(() => {
        setAlertModalShow(true);
        setAlertModalMsg(t('welcome') + mall_name );
        setTimeout(() => {
           setAlertModalShow(false);
        }, 3500);

        getProducts();
        getCategories();

    }, []);

    const getCategories = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`categories/malls`);
            setCategories(response.data);

            if (!response.data.length) {
                setMessageType('warning');
                setMessage('No existen categorias');
            } else {
                setMessage('');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const getProducts = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`products/malls/stores`);
            setProducts(response.data);

            if (!response.data.length) {
                setMessageType('warning');
                setMessage('El bar que seleccionaste no tiene productos asociados');
            } else {
                setMessage('');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <TextField
                label={t('label_search_all')}
                value={searchText}
                onChange={(e) => { e.preventDefault(); setSearchText(e.target.value); }}
                variant="outlined"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchText ?
                                <ClearIcon onClick={() => setSearchText('')} />
                                :
                                <SearchIcon />
                            }
                        </InputAdornment>
                    ),
                }}
            />

            {/* Stores list */}
            {!searchText ? 
                <StoreSlider setStoreFilter={setStoreFilter} />
            : ''}
            {/* Stores modal */}
            <Dialog open={!!storeFilter} onClose={() => setStoreFilter('')} fullScreen>
                <DialogTitle>{mall_name} </DialogTitle>
                <DialogContent>
                    {/* Products filtered by store*/}
                    {storeFilter ? products.map(store => (
                        <div key={store.id}>
                            {store.product.filter((product) => {
                                return product.store_id === storeFilter;
                            }).map(product => (
                                <div key={product.id}>
                                    <ProductCard product={product}></ProductCard>
                                </div>
                            ))}
                        </div>
                    )) : ''}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStoreFilter('')} color="primary">
                        {t('label_back')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Categories */}
            {!searchText ? 
                <Button fullWidth variant="outlined" onClick={() => setShowCategories(true)}>
                    {t('label_categories')}
                </Button>
             : ''}
            <br></br>
            <br></br>

            {/* Products filtered */}
            {searchText ? products.map(store => (
                <div key={store.id}>
                    {store.product.filter((product) => {
                        return (
                            product.name &&  product.name.toLowerCase().includes(searchText.toLowerCase()) ||
                            product.name_en && product.name_en.toLowerCase().includes(searchText.toLowerCase()) ||
                            product.description && product.description.toLowerCase().includes(searchText.toLowerCase()) ||
                            product.description_en && product.description_en.toLowerCase().includes(searchText.toLowerCase()) ||
                            product.style && product.style.toLowerCase().includes(searchText.toLowerCase()) ||
                            product.style_en &&  product.style_en.toLowerCase().includes(searchText.toLowerCase())
                        );
                    }).map(product => (
                        <div key={product.id}>
                            <ProductCard 
                                product={product} 
                                openProductView={() => {setShowProductView(true); setProductSelected(product)}}
                            ></ProductCard>
                        </div>
                    ))}
                </div>
            )) : ''}

            {/* Show all product when is only one store */}
            { products.length === 1 && !searchText ? products.map(store => (
                <div key={store.id}>
                    {store.product.map(product => (
                        <div key={product.id}>
                            <ProductCard 
                                product={product} 
                                openProductView={() => {setShowProductView(true); setProductSelected(product)}}
                            ></ProductCard>
                        </div>
                    ))}
                </div>
            )) : ''}

            {/* Product detail modal */}
            <ProductView
                show={showProductView} setShow={setShowProductView}
                product={productSelected} 
            />

            {/* Product sliders by store */}
            { products.length > 1 && !searchText ? products.map(store => (
                <React.Fragment key={store.id}>
                    {store.product.length ? <>
                        <h2> {store.name}</h2>
                        <StoreProductsSlider stores={store.product} />
                    </>
                        : <></>}
                </React.Fragment>
            )) : ''}

            <br></br>

            {/* Wellcome modal*/}
            <WelcomeModal
                show={alertModalShow}
                setShow={setAlertModalShow}
                msg={alertModalMsg} setMsg={setAlertModalMsg}
            />

            {/* Categories modal */}
            <Dialog open={showCategories} onClose={() => setShowCategories(false)} fullScreen>
                <DialogTitle>{mall_name} - {t('label_categories')}</DialogTitle>
                <DialogContent>
                    <CategoriesSlider categories={categories} products={products}></CategoriesSlider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowCategories(false)} color="primary">
                        {t('label_back')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        mall: state.mall,
        mall_name: state.mall_name,
        mall_image: state.mall_image,
        table: state.table,
    };
}

const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);

