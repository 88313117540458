import { AppBar, Box, Toolbar, ToggleButton, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function AppMenu(props) {
    const { isLoggedIn, username, mall_name } = props;

    const [language, setLanguage] = useState(localStorage.lang || 'Es');

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.lang = language;
    };

    useEffect(()=>{
        if (language == 'Es') {
            changeLanguage('es');
        } else {
            changeLanguage('en')
        }
    }, [language]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {mall_name}
                    </Typography>
                    <ToggleButton
                        value="language"
                        onChange={() => {
                            setLanguage(language == 'En' ? 'Es' : 'En');
                        }}
                    >
                        {language}
                    </ToggleButton>
                    <Button color="inherit"><AccountCircle /></Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

const mapStateToProps = state => {
    return {
      username: state.username,
      mall_name: state.mall_name,
      isLoggedIn: state.isLoggedIn,
    };
  };

export default connect(mapStateToProps)(AppMenu);

