import { connect } from 'react-redux';
import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import AppMenu from './top';

import BottomNavigationBar from './button'

import { useNavigate } from 'react-router-dom';

function Navigation(props) {
  const { isLoggedIn, username, mall_name } = props;

  const navigate = useNavigate();

  const isDesktopDevice = function () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /Mobi|Android/i.test(userAgent);
  
    return !isMobile;
  }

  useEffect(()=>{
    if (isDesktopDevice()) {
      navigate('/home');
    }
  }, []);

  return (
    <>
      {isLoggedIn ? 
        <AppMenu/>
      : ''}
      <br />
      <br />
      <Box sx={{ padding: 2 }}>
        <Outlet/>
        <br />
      </Box>
      {isLoggedIn ?
        <BottomNavigationBar/>
      : ''}
    </>
  );
}

const mapStateToProps = state => {
  return {
    username: state.username,
    mall_name: state.mall_name,
    isLoggedIn: state.isLoggedIn,
  };
};

export default connect(mapStateToProps)(Navigation);