import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { login, logout, setIsLoading } from '../general/actions';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Login(props) {
  const { tableToken } = useParams();

  const { isLoggedIn, login, logout,setIsLoading } = props;
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
    if (isLoggedIn) {
      navigate("/products");
    } else {
      navigate("/home");
    }
  }, [isLoggedIn]);

  // Initialize
  useEffect(() => {
    if (tableToken) {
      loginOnServer()
    } else {
      navigate("/home");
    }
  }, []);

  const loginOnServer = async () => {
    setIsLoading(true);

    try {
      if (isLoggedIn) {
        logout();
      }
      axios.post('/qr-login', { tableToken })
        .then(response => {
          setIsLoading(false);

          login({data: response.data});

          localStorage.setItem('token', response.data.token);
        })
        .catch(error => {
          navigate("/home");
          setIsLoading(false);
        });
    } catch (error) {
      navigate("/home");
      setIsLoading(false);
    }
  };

  return (
    <>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn
  };
}

const mapDispatchToProps = {
  login,
  logout,
  setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
